import Image from 'next/image'
import type React from 'react'
import { twMerge } from 'tailwind-merge'

import logoDark from '@/images/perceivable-logo-dark.png'
import logoLight from '@/images/perceivable-logo-light.png'

export default function PerceivableLogo({
  className,
  width = 32,
  height = 32,
  variant = 'dark',
  ...props
}: Omit<
  React.ComponentProps<typeof Image>,
  'src' | 'width' | 'height' | 'alt'
> & {
  width?: number
  height?: number
  variant?: string
}) {
  return (
    <Image
      alt="Perceivable Logo"
      width={width}
      height={height}
      {...props}
      className={twMerge('mx-auto h-8 w-auto text-black', className)}
      src={
        variant === 'dark'
          ? logoDark
          : logoLight
      }
      unoptimized
      priority
    />
  )
}
