import Image from 'next/image'
import Link from 'next/link'

import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { TextField } from '@/components/Fields'
import { Logomark } from '@/components/Logo'
import { NavLinks } from '@/components/NavLinks'
import qrCode from '@/images/qr-code.svg'
import PerceivableLogo from './PerceivableLogo'

function QrCodeBorder(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" {...props}>
      <path
        d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export function Footer() {
  return (
    <footer className="border-t border-gray-200">
      <Container>
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div>
            <div className="flex items-center text-gray-900">
              <Logomark className="h-10 w-10 flex-none fill-violet-500" />
              <div className="ml-2 flex justify-between items-center gap-x-3">
                <p className="text-base font-semibold">Slackpipe</p>
                <p className="mt-1 text-sm">
                  by <Link href="https://perceivable.co" title="Perceivable" className="underline text-violet-600"><PerceivableLogo className="inline-flex" /></Link></p>
              </div>
            </div>
            <nav className="mt-11 flex gap-8">
              <NavLinks />
            </nav>
          </div>
          <div className="relative -mx-4">
            <p className="ml-8 2-64 lg:w-96">Join the Perceivable newsletter to get updates about Slackpipe and our other products.</p>
            <div className="relative flex items-center self-stretch p-4 sm:self-auto sm:rounded-2xl lg:mx-0 lg:self-auto lg:p-6">
              <form className="flex w-full justify-center md:w-auto">
                <TextField
                  type="email"
                  aria-label="Email address"
                  placeholder="Email address"
                  autoComplete="email"
                  required
                  className="w-60 min-w-0 shrink"
                />
                <Button type="submit" color="violet" className="ml-4 flex-none">
                  <span className="hidden lg:inline">Join our newsletter</span>
                  <span className="lg:hidden">Join newsletter</span>
                </Button>
                </form>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 pb-12 pt-8 md:pt-6">
          <p className="mt-6 text-sm text-gray-500 md:mt-0">
            Slackpipe is built by <Link href="https://perceivable.co" className="underline text-violet-600">Perceivable</Link>.
            &copy; Copyright {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}
