import { STATUS_CODES } from "http"

export default class ErrorWithStatusCode extends Error {
  constructor(
    readonly description: string,
    readonly statusCode: number,
  ) {
    super(STATUS_CODES[statusCode])
  }
}

export function withErrorWithStatusCode(
  err: unknown,
): err is ErrorWithStatusCode {
  return (
    err instanceof Error &&
    typeof (err as ErrorWithStatusCode).statusCode === 'number'
  )
}
