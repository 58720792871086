"use client"

import {Layout as ErrorLayout} from '@/components/Layout'
import { withErrorWithStatusCode } from "@/lib/errors/ErrorWithStatusCode";

type ErrorProps = {
  error: Error;
  reset: () => void;
}

export default function ErrorPage({ error, reset }: ErrorProps) {
  const [statusCode, title, description] = withErrorWithStatusCode(error)
    ? [error.statusCode, error.message, error.description]
    : [500, 'Unexpected Error', error.message]

  return (
    <ErrorLayout>
      <div className="grid h-screen px-4 place-content-center">
        <div className="text-center">
          <h1 className="font-black text-gray-300 text-9xl">{statusCode}</h1>

          <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </p>

          {description.length > 0 && (
            <p className="mt-4 text-gray-500">
              {description}
            </p>
          )}

        {statusCode >= 500 && statusCode < 600 && (
          <button
          type="button"
          className="inline-block px-5 py-3 mt-6 text-sm font-medium text-white bg-violet-600 rounded hover:bg-violet-700 focus:outline-none focus:ring"
          onClick={() => reset()}
        >
          Try Again
        </button>
          )}
        </div>
      </div>
    </ErrorLayout>
  )
}
